import * as Types from '../Constants/AllConstants';

export const LoginAdminReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.ADMIN_LOGIN_REQUEST:
            return { loading: true};
        case Types.ADMIN_LOGIN_SUCCESS:
            return { loading: false, adminInfo: action.payload};
        case Types.ADMIN_LOGIN_FAIL:
            return { loading: false, error: action.payload};
        case Types.ADMIN_LOGIN_RESET || Types.ADMIN_LOGOUT:
            return {};
        default:
            return state;
    }
}
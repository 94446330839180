import * as Types from '../Constants/AllConstants'

// get all torneos

export const getAllTorneosReducer = (
    state = {
        torneos:[],
    },
    action
) => {
    switch (action.type) {
        case Types.TORNEO_LIST_REQUEST:
            return { loading: true };
        case Types.TORNEO_LIST_SUCCESS:
            return { loading: false, torneos: action.payload};
        case Types.TORNEO_LIST_FAIL:
            return { loading: false, error: action.payload};
        case Types.TORNEO_LIST_RESET:
            return { categories: [] };
        default:
            return state;
    }
};

// create torneo reducer
export const createTorneoReducer = (state = { torneo: {} }, action) => {
    switch (action.type) {
        case Types.TORNEO_CREATE_REQUEST:
            return { loading: true};
        case Types.TORNEO_CREATE_SUCCESS:
            return { loading: false, success: true, torneo: action.payload };     
        case Types.TORNEO_CREATE_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.TORNEO_CREATE_RESET:
            return { };       
        default:
            return state;
    }
}

// GET torneo BY ID reducer
export const getTorneoByIdReducer = (state = { torneo: {} }, action) => {
    switch (action.type) {
        case Types.TORNEO_GET_BY_ID_REQUEST:
            return { loading: true};
        case Types.TORNEO_GET_BY_ID_SUCCESS:
            return { loading: false, torneo: action.payload};     
        case Types.TORNEO_GET_BY_ID_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.TORNEO_GET_BY_ID_RESET:
            return { torneo: [] };       
        default:
            return state;
    }
}

// UPDATE torneo by id reducer
export const updateTorneoReducer = (state = { torneo: {} }, action) => {
    switch (action.type) {
        case Types.TORNEO_UPDATE_REQUEST:
            return { loading: true};
        case Types.TORNEO_UPDATE_SUCCESS:
            return { loading: false, success: true};
        case Types.TORNEO_UPDATE_FAIL:
            return { loading: false, error: action.payload};
        case Types.TORNEO_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

// DELETE torneo by id reducer
export const deleteTorneoReducer = (state = { torneo: {} }, action) => {
    switch (action.type) {
        case Types.TORNEO_DELETE_REQUEST:
            return { loading: true};
        case Types.TORNEO_DELETE_SUCCESS:
            return { loading: false, success: true};
        case Types.TORNEO_DELETE_FAIL:
            return { loading: false, error: action.payload};
        case Types.TORNEO_DELETE_RESET:
            return {};
        default:
            return state;
    }
}  
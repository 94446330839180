import * as Types from '../Constants/AllConstants'

// get all offers

export const getOffersReducer = (
    state = {
        offers:[],
    },
    action
) => {
    switch (action.type) {
        case Types.OFFER_LIST_REQUEST:
            return { loading: true };
        case Types.OFFER_LIST_SUCCESS:
            return { loading: false, offers: action.payload};
        case Types.OFFER_LIST_FAIL:
            return { loading: false, error: action.payload};
        case Types.OFFER_LIST_RESET:
            return { offers: [] };
        default:
            return state;
    }
};


// create offer reducer
export const createOfferReducer = (state = { offer: {} }, action) => {
    switch (action.type) {
        case Types.OFFER_CREATE_REQUEST:
            return { loading: true};
        case Types.OFFER_CREATE_SUCCESS:
            return { loading: false, success: true, offer: action.payload };     
        case Types.OFFER_CREATE_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.OFFER_CREATE_RESET:
            return { offer: {} };       
        default:
            return state;
    }
}

// DELETE offer by id reducer
export const deleteOfferReducer = (state = { offer: {} }, action) => {
    switch (action.type) {
        case Types.OFFER_DELETE_REQUEST:
            return { loading: true};
        case Types.OFFER_DELETE_SUCCESS:
            return { loading: false, success: true};
        case Types.OFFER_DELETE_FAIL:
            return { loading: false, error: action.payload};
        case Types.OFFER_DELETE_RESET:
            return {};
        default:
            return state;
    }
}  

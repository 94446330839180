import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as user from './Reducers/UserReducer';
import * as cat from './Reducers/CatReducer';
import * as banner from './Reducers/BannerReducer'
import * as offer from './Reducers/OfferReducer';
import * as prod from './Reducers/ProductReducer';
import * as order from './Reducers/OrderReducer';
import * as admin from './Reducers/AdminReducer';
import * as torneo from './Reducers/TorneoReducer';
import * as inscription from './Reducers/InscriptionReducer';


const rootReducer = combineReducers({
    // admin reducers
    adminLogin : admin.LoginAdminReducer,
    // user reducers
    userLogin : user.LoginReducer,
    userRegister : user.RegisterReducer,
    updateProfile : user.UpdateProfileReducer,
    updateAddress : user.UpdateAddressReducer,
    deleteAddressById : user.DeleteAddressByIdReducer,
    updateAddressById : user.UpdateAddressByIdReducer,
    changePassword : user.ChangePasswordReducer,
    deleteAccount: user.DeleteAccountReducer,
    getAllUsers: user.getAllUsersReducer,
    getUserById: user.getUserByIdReducer,
    updateUserById: user.updateUserReducer,
    deleteUserById: user.deleteUserReducer,
    // category reducers
    categoriesList: cat.getCategories,
    getCategoryById: cat.getCategoryByIdReducer,
    updateCategory: cat.updateCategoryReducer,
    deleteCategory: cat.deleteCategoryReducer,
    createCategory: cat.createCategoryReducer,
     // banner reducers
     getBannersBySection: banner.getBannersBySectionReducer,
     createBanner: banner.createBannerReducer,
     updateBanner: banner.updateBannerReducer,
     deleteBanner: banner.deleteBannerReducer,
     // offer reducers
     offerList: offer.getOffersReducer,
     createOffer: offer.createOfferReducer,
     deleteOffer: offer.deleteOfferReducer,
    // torneo reducers
    torneosList: torneo.getAllTorneosReducer,
    createTorneo: torneo.createTorneoReducer,
    getTorneoById: torneo.getTorneoByIdReducer,
    updateTorneo: torneo.updateTorneoReducer,
    deleteTorneo: torneo.deleteTorneoReducer,
    // product reducers
    productList: prod.ProductListReducer,
    singleProduct: prod.GetSingleProductReducer,
    tagsProduct: prod.TagsProductReducer,
    productsAdminList: prod.getAllProductsForAdminPanelReducer,
    updateProduct: prod.UpdateProductReducer,
    deleteProduct: prod.DeleteProductReducer,
    createProduct: prod.createProductReducer,
    // cart reducers
    cart: prod.CartReducer,
    // order reducers
    createOrder: order.createOrderReducer,
    getAllOrdersByUser: order.getAllOrdersByUserReducer,
    getAllOrdersByAdmin: order.getAllOrdersByAdminReducer,
    getOrderById: order.getOrderByIdReducer,
    updateOrder: order.updateOrderReducer,
    getOrderByIdAdminPanel: order.getOrderByIdAdminPanelReducer,
    deleteOrder: order.deleteOrderReducer,
    deleteAllOrders: order.deleteAllOrdersReducer,
    stripeCheckout: order.stripeCheckoutReducer,
    stripeInscriptionCheckout: order.stripeInscriptionCheckoutReducer,
    // inscription reducers
    createInscription: inscription.createInscriptionReducer,
    getAllInscriptionsByUser: inscription.getAllInscriptionsByUserReducer,
    getAllInscriptionsByAdmin: inscription.getAllInscriptionsByAdminReducer,
    updateInscription: inscription.updateInscriptionReducer,
    deleteInscription: inscription.deleteInscriptionReducer,
});


// get userInfo from localStorage
const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

// get adminInfo from localStorage
const adminInfoFromStorage = localStorage.getItem('adminInfo')
    ? JSON.parse(localStorage.getItem('adminInfo'))
    : null;    

// get cartItems from localStorage
const cartItemsFromStorage = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : [];


const initialState = {
    adminLogin: { adminInfo: adminInfoFromStorage},
    userLogin: { userInfo: userInfoFromStorage},
    cart: { cartItems: cartItemsFromStorage }
};

export const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
});
import * as Types from '../Constants/AllConstants'

// create inscription reducer
export const createInscriptionReducer = (state = { inscription: {} }, action) => {
    switch (action.type) {
        case Types.INSCRIPTION_CREATE_REQUEST:
            return { loading: true};
        case Types.INSCRIPTION_CREATE_SUCCESS:
            return { loading: false, success: true, inscription: action.payload };     
        case Types.INSCRIPTION_CREATE_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.INSCRIPTION_CREATE_RESET:
            return { inscription: {} };       
        default:
            return state;
    }
}

// GET INSCRIPTIONS BY USER reducer
export const getAllInscriptionsByUserReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.INSCRIPTION_GET_ALL_REQUEST:
            return { loading: true};
        case Types.INSCRIPTION_GET_ALL_SUCCESS:
            return { 
                loading: false, 
                inscriptions: action.payload.inscriptions,
                total: action.payload.total,
                pending: action.payload.pending,
                paid: action.payload.paid,
                cancelled: action.payload.cancelled,
            };     
        case Types.INSCRIPTION_GET_ALL_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.INSCRIPTION_GET_ALL_RESET:
            return { inscriptions: [] };       
        default:
            return state;
    }
}

// GET Inscriptions BY ADMIN reducer
export const getAllInscriptionsByAdminReducer = (state = { inscriptions: []  }, action) => {
    switch (action.type) {
        case Types.INSCRIPTION_GET_ALL_ADMIN_REQUEST:
            return { loading: true};
        case Types.INSCRIPTION_GET_ALL_ADMIN_SUCCESS:
            return { 
                loading: false, 
                inscriptions: action.payload,
            };     
        case Types.INSCRIPTION_GET_ALL_ADMIN_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.INSCRIPTION_GET_ALL_ADMIN_RESET:
            return { inscriptions: [] };       
        default:
            return state;
    }
}

// UPDATE INSCRIPTION by id reducer
export const updateInscriptionReducer = (state = { inscription: {} }, action) => {
    switch (action.type) {
        case Types.INSCRIPTION_UPDATE_REQUEST:
            return { loading: true};
        case Types.INSCRIPTION_UPDATE_SUCCESS:
            return { loading: false, success: true};
        case Types.INSCRIPTION_UPDATE_FAIL:
            return { loading: false, error: action.payload};
        case Types.INSCRIPTION_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

// DELETE Inscription by id reducer
export const deleteInscriptionReducer = (state = { inscription: {} }, action) => {
    switch (action.type) {
        case Types.INSCRIPTION_DELETE_REQUEST:
            return { loading: true};
        case Types.INSCRIPTION_DELETE_SUCCESS:
            return { loading: false, success: true};
        case Types.INSCRIPTION_DELETE_FAIL:
            return { loading: false, error: action.payload};
        case Types.INSCRIPTION_DELETE_RESET:
            return {};
        default:
            return state;
    }
}  
import { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react';

export default function ToastContainer() {
  const [position, setPosition] = useState('bottom-right');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setPosition('top-center');
      } else {
        setPosition('bottom-right');
      }
    };

    // Llamar al inicio para establecer la posición inicial
    handleResize();

    // Agregar event listener para cambios en el tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Toaster
      position={position}
      reverseOrder={false}
      gutter={8}
      toastOptions={{
        duration: 2000,
        className: 'outfit rounded border',
      }}
    />
  );
}

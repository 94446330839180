import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedAdminRouter() {
    const { adminInfo} = useSelector((state) => state.adminLogin);

    return adminInfo?.token ? <Outlet /> : <Navigate to="/loginadmin" />
}

export {ProtectedAdminRouter};

